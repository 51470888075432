<template>
  <div class="home ma-2" ref="top">
    <v-card class="ma-2">
      <v-card-title>Selecione uma Imagen</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-file-input
              v-model="img"
              placeholder="Upload seu XML"
              prepend-icon="mdi-image-area"
              @change="pickFile"
              ref="file"
              accept="application/xml"
            >
            </v-file-input>
          </v-col>
          <v-col cols="2">
            <v-btn @click="send" color="success">Ler XML</v-btn>
          </v-col>
        </v-row>
        <v-row v-show="response">
          <v-spacer></v-spacer>
          <v-btn @click="backTop" color="primary">Voltar ao Topo</v-btn>
        </v-row>
        <v-row>
          <v-col>
            <pre ref="response">{{ response }}</pre>
          </v-col>
        </v-row>
        <v-row v-show="response.length > 0">
          <v-spacer></v-spacer>
          <v-btn @click="backTop" color="primary">Voltar ao Topo</v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Tesseract",
  data: () => ({
    img: null,
    response: "",
    picture: null,
  }),
  methods: {
    pickFile() {
      console.log("pick");
      console.log(this.img);
      let file = this.img;
      if (file) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.picture = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    send() {
      let formData = new FormData();
      this.response = "";
      formData.append("picture", this.img);
      this.$http
        .post("/xml", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          if (resp.status < 299) {
            this.$store.dispatch("resetSnack");
            this.$store.dispatch("showSuccessSnack", "Imagem Lida");
            console.log(resp.data);
            this.response = resp.data;
            this.$vuetify.goTo(this.$refs.response, {
              duration: 300,
              offset: 0,
              easing: "easeInOutCubic",
            });
          }
        });
    },
    backTop() {
      this.$vuetify.goTo(this.$refs.top, {
        duration: 300,
        offset: -10,
        easing: "easeInOutCubic",
      });
    },
  },
};
</script>
